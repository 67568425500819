@import "mixin";
* {
  box-sizing: border-box;
  outline: none; }
.wrp {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: row;
  width: 100%;
  overflow-x: hidden;
  .content {
    width: 100%;
    height: auto;
    //min-height: 100vh
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    overflow: hidden;
    position: relative;
    &_withLeftSideBar {
      margin-left: 218px;
      @media screen and (max-width: 1366px) {
        margin-left: 0; } }
    &_wrp {
      padding: 50px;
      min-height: calc(100vh - 186px);
      background: #EFEFEF;
      @media screen and (max-width: 1366px) {
        padding: 0; }
      @include for-phone {
        min-height: calc(100vh - 161px); }
      &Guru {
        min-height: calc(100vh - 181px); } }
    &_course {
      display: none;
      &.open {
        display: block; } }
    .lesson, .test_wrap {
      display: flex;
      flex-direction: column-reverse; }
    &_lesson,
    &_test {
      padding: 0 50px 50px;
      min-height: calc(100vh - 186px);
      background: #fff;
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      flex-direction: column;
      margin: -50px 234px -50px -50px;
      @media screen and (max-width: 1366px) {
        margin: 0; }
      @include for-phone {
        min-height: calc(100vh - 161px);
        padding: 40px 20px 20px; }
      .step,
      .test {
        width: 100%; }
      .test {
        max-width: 864px;
        margin: 0 auto; }
      &_step {
        margin: -50px;
        @include for-phone {
          margin: 0;
          padding: 0; } } }
    &_lesson {
      .btn_default, .btn_violet {
        max-width: 144px;
        &.les2 {
          max-width: 192px; }
        @include for-phone {
          max-width: 135px;
          height: 30px;
          font-size: 12px; } } }
    &_head {
      position: relative;
      display: flex;
      align-items: center;
      font-size: 40px;
      line-height: 48px;
      letter-spacing: 0.02em;
      color: #77777B;
      margin: 0 -50px 0 -50px;
      padding: 33px 0 23px 60px;
      background-color: #DEDEDE;
      cursor: pointer;
      transition: 0.5s;
      -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
      @media screen and (max-width: 1366px) {
        margin: 0; }
      @media screen and (max-width: 767px) {
        padding-left: 44px;
        font-size: 24px;
        line-height: 30px; }
      @media screen and (max-width: 500px) {
        padding-left: 20px;
        padding-right: 55px; }
      &_icon {
        fill: #77777B;
        margin-right: 20px;
        transition: 0.5s; }
      &_arrow {
        fill: #77777B;
        position: absolute;
        top: 50%;
        right: 60px;
        transform: translate(0, -50%);
        transition: 0.5s;
        @media screen and (max-width: 500px) {
          right: 20px; } }
      &.active {
        color: #4147DA;
        background-color: transparent;
        margin-bottom: 40px;
        @media screen and (max-width: 500px) {
          margin-bottom: 0; }
        .content_head {
          &_icon {
            fill: #4147DA; }
          &_arrow {
            fill: #4147DA;
            transform: rotate(180deg); } } } }
    &_topic {
      &_head {
        margin-bottom: 24px;
        margin-left: 24px;
        font-size: 32px;
        line-height: 40px;
        @media screen and (max-width: 1366px) {
          margin-left: 74px; }
        @media screen and (max-width: 767px) {
          margin-left: 44px;
          font-size: 18px;
          line-height: 26px;
          font-weight: bold; }
        @media screen and (max-width: 767px) {
          margin-left: 20px; }
        @media screen and (max-width: 500px) {
          margin-top: 20px; } } }
    &_statistic_head {
      margin: 72px 0 24px;
      font-size: 32px;
      line-height: 40px;
      color: #4147DA;
      @media screen and (max-width: 1366px) {
        margin-left: 74px; }
      @media screen and (max-width: 767px) {
        margin-left: 44px; }
      @media screen and (max-width: 767px) {
        display: none; } }
    .test {
      @include for-phone {
        display: flex;
        flex-direction: column; }
      .subheader {
        @include for-phone;
        display: none; }
      .TextBlock {
        @include for-phone {
          order: 2; } }
      .answers {
        @include for-phone {
          order: 3; } }
      .QuestionCorrect {
        @include for-phone {
          order: 1; } }
      .MainButton {
        @include for-phone {
          order: 4; } }
      .InfoBlock {
        display: none;
        @include for-phone {
          display: flex;
          margin-bottom: 20px; }
        &.hidden {
          @include for-phone {
            display: none; } } } }
    .Popup {
      &_closed {
        //display: none
        @include for-phone {
          display: flex; } }
      &_block {
        padding: 24px; }
      &_wrp {
        padding: 80px 50px 50px;
        border: 8px dashed #EFEFEF;
        box-sizing: border-box;
        border-radius: 24px; }
      &_img {
        position: absolute;
        top: 0;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 192px;
        height: 192px;
        border-radius: 50%;
        background: #fff;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 36px;
        @media screen and (max-width: 767px) {
          width: 155px;
          height: 155px;
          position: relative;
          padding: 0;
          transform: translate(-50%, 0); } }
      &_head {
        margin-bottom: 24px;
        font-weight: bold;
        font-size: 40px;
        line-height: 48px;
        text-align: center;
        letter-spacing: 0.02em;
        color: #4147DA; }
      &_text {
        margin-bottom: 24px;
        font-size: 30px;
        line-height: 36px;
        text-align: center;
        letter-spacing: 0.02em; }
      &_description {
        margin-bottom: 32px;
        font-weight: 250;
        font-size: 16px;
        line-height: 24px;
        text-align: center;
        letter-spacing: 0.02em;
        color: #77777B; }
      &_noCloseButton {
        display: none;
        @include for-phone {
          display: flex; } } } }
  .noMatch {
    width: 100%;
    height: calc(100vh - 200px);
    display: flex;
    justify-content: center;
    align-items: center; } }
//Popup для первого показа
.Popup {
  .Popup_block {
    padding: 24px; }
  &_wrp.certificate {
    padding: 60px 40px 50px;
    border: 8px dashed #EFEFEF;
    box-sizing: border-box;
    border-radius: 24px;
    .Popup {
      &_closed {
        display: block; }
      &_img {
        position: absolute;
        top: 0;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 192px;
        height: 192px;
        border-radius: 50%;
        background: #fff;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 36px;
        @media screen and (max-width: 767px) {
          width: 155px;
          height: 155px;
          position: relative;
          padding: 0;
          transform: translate(-50%, 0); } }
      &_head {
        margin-bottom: 24px;
        font-weight: bold;
        font-size: 30px;
        line-height: 36px;
        text-align: center;
        letter-spacing: 0.02em; }
      &_text {
        max-width: 420px;
        margin: 0 auto 18px;
        font-size: 16px;
        line-height: 24px;
        white-space: pre-line;
        letter-spacing: 0.02em; }
      &_description {
        padding: 18px 30px;
        font-size: 16px;
        line-height: 24px;
        letter-spacing: 0.02em;
        border: 2px solid #4147DA;
        border-radius: 12px; } } } }
