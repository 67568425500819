@mixin for-little {
  @media (max-width: 359px) {
    @content; } }

@mixin for-phone {
  @media (max-width: 767px) {
    @content; } }

@mixin for-table {
  @media (max-width: 920px) {
    @content; } }

@mixin for-CircleBlock {
  @media (max-width: 930px) {
    @content; } }

@mixin for-tablet {
  @media (max-width: 1023px) {
    @content; } }

@mixin for-desktop-little {
  @media (max-width: 1279px) {
    @content; } }

@mixin for-desktop {
  @media (max-width: 1366px) {
    @content; } }

@mixin for-hd {
  @media (min-width: 1440px) {
    @content; } }

@mixin for-1400 {
  @media (max-width: 1400px) {
    @content; } }

@mixin for-1000 {
  @media (max-width: 1050px) {
    @content; } }

@mixin for-480 {
  @media (max-width: 480px) {
    @content; } }
