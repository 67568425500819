@font-face {
  font-family: "VAG Rounded LT";
  font-weight: 250;
  font-style: normal;
  src: url("fonts/VAGRoundedLTCYR-Thin.woff2") format("woff2"), url("fonts/VAGRoundedLTCYR-Thin.woff") format("woff");
  font-display: swap;
}

@font-face {
  font-family: "VAG Rounded LT";
  font-weight: 300;
  font-style: normal;
  src: url("fonts/VAGRoundedLTCYR-Light.woff2") format("woff2"), url("fonts/VAGRoundedLTCYR-Light.woff") format("woff");
  font-display: swap;
}

@font-face {
  font-family: "VAG Rounded LT";
  font-weight: 400;
  font-style: normal;
  src: url("fonts/VAGRoundedLTCYR-Black.woff2") format("woff2"), url("fonts/VAGRoundedLTCYR-Black.woff") format("woff");
  font-display: swap;
}

@font-face {
  font-family: "VAG Rounded LT";
  font-weight: 700;
  font-style: normal;
  src: url("fonts/VAGRoundedLTCYR-Bold.woff2") format("woff2"), url("fonts/VAGRoundedLTCYR-Bold.woff") format("woff");
  font-display: swap;
}

@font-face {
  font-family: "RotondaC";
  font-weight: 700;
  font-style: normal;
  src: url("fonts/rotondac-bold.otf") format("opentype");
  font-display: swap;
}

@font-face {
  font-family: "RotondaC";
  font-weight: 400;
  font-style: normal;
  src: url("fonts/rotondac.otf") format("opentype");
  font-display: swap;
}


body {
  margin: 0;
  overflow-y: scroll;
  font-family: 'VAG Rounded LT', sans-serif;
  font-weight: 300;
  color: #333333;
}

.visually-hidden:not(:focus):not(:active),
input[type="checkbox"].visually-hidden,
input[type="radio"].visually-hidden,
input[type="file"].visually-hidden {
  position: absolute;

  width: 1px;
  height: 1px;
  margin: -1px;
  border: 0;
  padding: 0;

  white-space: nowrap;

  clip-path: inset(100%);
  clip: rect(0 0 0 0);
  overflow: hidden;
}
